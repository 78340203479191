import React, {useContext, useEffect, useState} from "react";
import {Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Tooltip} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {MainContext} from "../../../App";
import {Server} from "../../../common/server";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {Save} from "@mui/icons-material";
import {clear} from "../../../common/flup";

const EditVocabularyContext = React.createContext({});
const FormContext = React.createContext({});

const Snack = () => {
    const {isLoading} = useContext(EditVocabularyContext);

    return isLoading && (
        <Dialog open={true}>
            <MuiAlert style={{border: "solid 1px #ffffff"}} variant="filled" severity="success">
                Success!
            </MuiAlert>
        </Dialog>
    )
}
const TextArea = () => {
    const {text, setText, dText} = useContext(FormContext);

    const onChange = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        setText(dText)
    }, [dText, setText]);

    return (
        <textarea
            rows={3}
            style={{height: "100%", width: "calc(100% - 6px)"}}
            value={text}
            onChange={onChange}
        />
    )
}
const TransArea = () => {
    const {trans, setTrans, dTrans} = useContext(FormContext);

    const onChange = (e) => {
        setTrans(e.target.value)
    }

    useEffect(() => {
        setTrans(dTrans);
    }, [dTrans, setTrans]);

    return (
        <textarea
            rows={3}
            style={{height: "100%", width: "calc(100% - 6px)"}}
            value={trans}
            onChange={onChange}
        />
    )
}

const DeleteButton = () => {
    const {isLoading, setIsLoading, index, setItems, items, onClose} = useContext(EditVocabularyContext);

    const deleteItem = async () => {
        if (!window.confirm("Are you sure?")) return;
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            setItems(items.filter((item, i) => index !== i));
            onClose();
        }, 300);
        await Server.deleteFromVocabulary(index)
    }

    return index !== undefined && (
        <Tooltip title={"Delete"} arrow placement="top">
            <Button color={"error"} style={{width: 0}} variant={"contained"} disabled={isLoading} onClick={deleteItem}>
                <DeleteIcon/>
            </Button>
        </Tooltip>
    )
}
const SaveButton = () => {
    const {settings: {lang, myLang}} = useContext(MainContext);
    const {isLoading, setIsLoading, index, onClose, setItems, items} = useContext(EditVocabularyContext);
    const {text, trans} = useContext(FormContext);

    const save = async () => {
        setIsLoading(true);

        const item = {[lang]: clear(text) || "", [myLang]: clear(trans) || "", date: +new Date(), notLearned: true};

        if (items) {
            index !== undefined ? items[index] = item : items.push(item);
            setItems([...items]);
        }

        onClose();
        setTimeout(() => setIsLoading(false), 300);
        await Server.saveToVocabulary({...item, index});
    };

    return (
        <Button fullWidth startIcon={<Save />} variant={"contained"} disabled={isLoading} onClick={save}>
            Save
        </Button>
    )
}
const Form = () => {
    const {text: dText, trans: dTrans} = useContext(EditVocabularyContext);

    const [text, setText] = useState(dText);
    const [trans, setTrans] = useState(dTrans);

    const value = {text, setText, trans, setTrans, dText, dTrans};

    return (
        <FormContext.Provider value={value}>
            <TextArea/>
            <TransArea/>
            <ButtonGroup fullWidth>
                <DeleteButton/>
                <SaveButton/>
            </ButtonGroup>
        </FormContext.Provider>
    )
}

export const EditVocabulary = ({open, text, trans, items, setItems, index, onClose}) => {
    const [isLoading, setIsLoading] = useState(false);

    const value = {text, trans, isLoading, setIsLoading, setItems, items, index, onClose};

    return (
        <EditVocabularyContext.Provider value={value}>
            <Dialog open={open}>
                <DialogTitle sx={{m: 0, pl: 2}}>Edit</DialogTitle>
                <IconButton onClick={onClose}><CloseIcon/></IconButton>
                <DialogContent>
                    <Form/>
                    <Snack/>
                </DialogContent>
            </Dialog>
        </EditVocabularyContext.Provider>
    )
}