import {useContext, useEffect, useState} from "react";
import {TrainingContext} from "../training";
import {TextField} from "@mui/material";
import {clear} from "../../../common/flup";
import css from "./input-word.module.css";

export const InputWord = () => {
    const {setAnswer, text, setTypeWithout} = useContext(TrainingContext);
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue("");
        if (clear(text).includes(" ")) setTypeWithout(InputWord);
    }, [text])

    return (
        <TextField className={css.inputWord} id="" variant="outlined" value={value} onChange={({target: {value}}) => {
            setValue(value);
            setAnswer(value)
        }}/>
    )
}
