import {languages} from "./languages";
import * as React from "react";
import {useContext, useState} from "react";
import {Server} from "./server";
import {Button} from "@mui/material";
import {Line} from "./line";
import {MainContext} from "../App";

export const Settings = ({onClose}) => {
    const {settings, setSettings} = useContext(MainContext);
    const [myLang, setMyLang] = useState(settings.myLang);
    const [lang, setLang] = useState(settings.lang);

    const onClickHandle = () => {
        setSettings({...settings, myLang, lang});
        Server.setSettings({...settings, myLang, lang});
        onClose()
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "space-around", marginBottom: 40, textAlign: "center"}}>
                <Line title={"My language"} value={myLang} options={languages} onChange={setMyLang}/>
                <Line title={"Language to learn"} value={lang} options={languages} onChange={setLang}/>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Button size={"large"} variant={"contained"} onClick={onClickHandle}>Save</Button>
            </div>
        </div>
    )
}