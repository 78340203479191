import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {MainContext} from "../../../App";
import css from "./video-header.module.css";

export const VideoHeader = () => {
    const {prevURL} = useContext(MainContext);
    const navigate = useNavigate();

    const onClose = () => navigate(prevURL || "/");

    return (
        <div className={css.videoHeader}>
            <div className={css.back}/>
            <IconButton className={css.closeButton} onClick={onClose}>
                <CloseIcon/>
            </IconButton>
        </div>
    )
}