import React, {useEffect, useState} from "react";
import {Server} from "../../common/server";
import MUIDialog from "@mui/material/Dialog";
import {DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import css from "./admin.module.css";

const context = {};

const Dialog = ({size, label, open, component: Component, onClose}) => {
    if (open && size === "none") return Component();
    return (
        <MUIDialog maxWidth={size} fullWidth onClose={onClose} open={open}>
            <DialogTitle sx={{m: 0, p: 2}}>{label}</DialogTitle>
            <IconButton onClick={onClose} sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}><CloseIcon/></IconButton>
            <DialogContent dividers>
                <Component onClose={onClose}/>
            </DialogContent>
        </MUIDialog>
    );
}
const toRusDate = (isoString) => {
    if (!isoString) return;
    const date = new Date(isoString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}
const isPro = (pro) => {
    if (!pro) return;
    const today = new Date();
    for (const i in pro) {
        const [start, end] = pro[i];
        if (today >= new Date(start) && today <= new Date(end)) return true
    }
}
const Pro = ({user, pro}) => {
    const [start, setStart] = useState(new Date().toISOString().split("T")[0]);
    const [end, setEnd] = useState(new Date().toISOString().split("T")[0]);

    const onDelete = (i) => {
        Server.adminDeletePro({user, i}).then(context.onChange)
    }

    const onAdd = () => {
        Server.adminAddPro({
            user,
            start: +new Date(start + " 00:00:00"),
            end: +new Date(end + " 23:59:59")
        }).then(context.onChange)
    }

    return (
        <>
            <input type={"date"} value={start} onChange={e => setStart(e.target.value)}/>
            <input type={"date"} value={end} onChange={e => setEnd(e.target.value)}/>
            <button onClick={onAdd}>Add</button>
            <hr/>
            <table border={1}>
                <tbody>
                {pro.map(([start, end], i) => (
                    <tr key={i}>
                        <td>{toRusDate(start)}</td>
                        <td>{toRusDate(end)}</td>
                        <td>
                            <button onClick={() => window.confirm("Delete?") && onDelete(i)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}
const ProButton = ({item: {user, pro}}) => {
    const [open, setOpen] = useState(false);

    const isPro1 = isPro(pro);

    return (
        <>
            <Dialog size={"md"} label={"Pro"} open={open} component={() => <Pro user={user} pro={pro || []}/>}
                    onClose={() => setOpen(false)}/>
            <button style={{background: isPro1 ? "green" : "red"}}
                    onClick={() => setOpen(true)}>{isPro1 ? "Pro" : "Free"}</button>
        </>
    )

}
const PromptButton = ({data: [i, oldData, title, ServerFunction, isPassword]}) => {
    const Prompt = (i, oldData, title, ServerFunction) => {
        const newData = prompt(title, oldData);
        if (!newData) return;
        ServerFunction(i, newData).then(context.onChange);
    }
    return (
        <>
            <button onClick={() => Prompt(i, oldData, title, ServerFunction)}>...</button>
            &nbsp;{isPassword ? <input readOnly type={"password"} value={oldData}/> : oldData}
        </>
    )
}
const DeleteAccount = ({i}) => {
    return (
        <button
            onClick={() => window.confirm("Delete?") && Server.adminDeleteAccount(i).then(context.onChange)}
        >
            Delete account
        </button>
    )
}
const BanAccount = ({banned, i}) => {

    return (
        <button
            style={{background: banned ? "red" : "green"}}
            onClick={() => Server.adminBanAccount(i).then(context.onChange)}
        >
            {banned ? "Banned" : "Available"}
        </button>

    )
}
export const Admin = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        context.onChange = () => Server.admin().then(setItems);
        context.onChange();
    }, [])

    return (
        <div className={css.admin}>
            <h1>Admin dashboard</h1>
            <table>
                <thead>
                <tr>
                    <th>User</th>
                    <th>My lang</th>
                    <th>Lang</th>
                    <th>Password</th>
                    <th>Pro</th>
                    <th>Delete account</th>
                    <th>Ban account</th>
                    <th>Created</th>
                    <th>Tokens</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, i) => (
                    <tr key={i}>
                        <td><PromptButton data={[i, item.user, "Username", Server.adminNewUserName]}/></td>
                        <td><PromptButton data={[i, item.settings.myLang, "My lang", Server.adminNewMylang]}/></td>
                        <td><PromptButton data={[i, item.settings.lang, "Lang", Server.adminNewLang]}/></td>
                        <td><PromptButton data={[i, item.password, "Password", Server.adminNewPassword, 1]}/></td>
                        <td><ProButton item={item}/></td>
                        <td><DeleteAccount i={i}/></td>
                        <td><BanAccount banned={item.banned} i={i}/></td>
                        <td>{toRusDate(item.created)}</td>
                        <td>{item.tokens.length}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}