import React from 'react';
import css from "./pay.module.css";
import {PaypalButton} from "./paypal/paypal-button/paypal-button";

export function Pay() {
    return (
        <div className={css.pay}>
            <PaypalButton/>
        </div>
    );
}

