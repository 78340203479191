import YouTube from "react-youtube";
import React, {useContext, useEffect} from "react";
import {VideoContext} from "../video";
import css from "./youtube.module.css";

export const Youtube = () => {
    const {videoId, setPlayer, setIsPlaying, player, captions, setIndex} = useContext(VideoContext);

    useEffect(() => {
        if (!player || !captions) return;
        const interval = setInterval(() => {
            setIndex(((playerCurrentTime) => {
                let index = 0;
                for (const i in captions) {
                    const [tStartMs] = captions[i];
                    if (tStartMs > playerCurrentTime) {
                        return index
                    }
                    index = +i;
                }
                return index;
            })(player.getCurrentTime() * 1000));
        }, 10);
        return () => clearInterval(interval);
    }, [captions, player]);

    return (
        <YouTube
            className={css.youtube}
            videoId={videoId}
            rel={"0"}
            opts={{rel: 0, playerVars: {rel: 0}}}
            onReady={(e) => setPlayer(e.target)}
            onStateChange={(e) => setIsPlaying(e.data === 1)}
        />
    )
}
