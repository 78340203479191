import {storage} from "./storage";

const [protocol, , domainPort] = document.location.href.split("/");
const localServer = `${protocol}//${domainPort.split(":")[0]}:1000`;


const server = process.env.NODE_ENV === "development" ? localServer : "";

const post = (path, data = {}) => {
    const token = storage("token");

    return fetch(`${server}${path}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({...data, token})
    }).then(response => {
        if (response.status === 401) {
            return []
        }
        return response.json();
    })
}

export const Server = {
    autocomplete: (term) => post("/server/autocomplete/", {term}),
    getSearchCache: () => post("/server/get-search-cache/"),

    translate: (lang, myLang, text) => post("/server/translate/", {text, lang, myLang}),

    readFromVocabulary: () => post("/server/read-from-vocabulary/"),
    deleteFromVocabulary: (i) => post("/server/delete-from-vocabulary/", {i}),
    saveToVocabulary: (data) => post("/server/save-to-vocabulary/", {data}),

    readFromHistory: () => post("/server/read-from-history/"),
    searchVideos: (item, lang, address, searchPageToken, chartPageToken) => post("/server/search-videos/", {item, lang, address, searchPageToken, chartPageToken}),
    setSettings: (settings) => post("/server/set-settings/", {settings}),
    getSettings: () => post("/server/get-settings/"),
    getCaptions: (videoId, lang) => post("/server/get-captions/", {lang, videoId}),

    createAccount: (user, password, settings) => post("/server/create-account/", {user, password, settings}),
    login: (user, password, settings) => post("/server/login/", {user, password, settings}),
    admin: () => post("/server/admin/"),
    adminAddPro: (pro) => post("/server/admin-add-pro/", {pro}),
    adminDeletePro: (pro) => post("/server/admin-delete-pro/", {pro}),
    adminDeleteAccount: (i) => post("/server/admin-delete-account/", {i}),
    adminBanAccount: (i) => post("/server/admin-ban-account/", {i}),
    adminNewUserName: (i, name) => post("/server/admin-set-username/", {i, name}),
    adminNewPassword: (i, password) => post("/server/admin-set-password/", {i, password}),
    adminNewMylang: (i, myLang) => post("/server/admin-set-setting/", {i, key: "myLang", value: myLang}),
    adminNewLang: (i, lang) => post("/server/admin-set-setting/", {i, key: "lang", value: lang}),

    saveToScores: (item, date, time) => post("/server/save-to-scores/", {item, date, time}),
    readFromScores: () => post("/server/read-from-scores/"),
}


