import React, {useContext, useState} from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {languages} from "../../common/languages";
import {storage} from "../../common/storage";
import {Server} from "../../common/server";
import {CircularProgress} from "@mui/material";
import {Line} from "../../common/line";
import {MainContext} from "../../App";
import css from "./login.module.css";


const Login = () => {
    const {setSettings, windowSize: {height}} = useContext(MainContext);

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [isRegistering, setIsRegistering] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [myLang, setMyLang] = useState(languages.find(l => l.value === "ru").value);
    const [lang, setLang] = useState(languages.find(l => l.value === "en").value);

    const toggleRegister = (e) => {
        e.preventDefault();
        setIsRegistering((prevValue) => !prevValue);
    };

    const createAccountOrLogin = async () => {
        if (isRegistering) {
            return await Server.createAccount(user, password, {lang, myLang});
        } else {
            return await Server.login(user, password, {lang, myLang});
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setError(false);
            setIsLoading(true);
            const {token, settings} = await createAccountOrLogin();
            storage("token", token);
            setSettings(settings);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setError(true)
        }
    };

    return (
        <Container className={css.login} style={{height}}>
            <Paper elevation={3} className={css.paper}>
                <h2>{isRegistering ? "Sign up" : "Log in"}</h2>
                <form onSubmit={handleSubmit} className={css.form}>
                    <TextField InputLabelProps={{shrink: true}} error={error} label="Email" type={"email"} required
                               fullWidth value={user} onChange={(e) => setUser(e.target.value)}/>
                    <TextField InputLabelProps={{shrink: true}} error={error} label="Password" required fullWidth
                               value={password} onChange={(e) => setPassword(e.target.value)} type="password"/>

                    {isRegistering && (
                        <div style={{display: "flex", justifyContent: "space-around", marginBottom: 20}}>
                            <Line title={"My language"} value={myLang} options={languages} onChange={setMyLang}/>
                            <Line title={"Language to learn"} value={lang} options={languages} onChange={setLang}/>
                        </div>
                    )}

                    {isLoading && <div style={{textAlign: "center"}}><CircularProgress/></div>}
                    <Button disabled={isLoading} type="submit" variant="contained" color="primary" fullWidth>
                        {isRegistering ? "Sign up" : "Log in"}
                    </Button>
                    <div style={{cursor: "pointer"}} onClick={toggleRegister}>
                        {isRegistering ? "Already have an account? Log in" : "Don't have an account? Sign up"}
                    </div>
                </form>
            </Paper>
        </Container>
    );
};

export default Login;
