import {storage} from "./storage";
import {useContext, useEffect} from "react";
import {MainContext} from "../App";

export const Logout = () => {
    const {setSettings, setDialogData} = useContext(MainContext);

    useEffect(() => {
        storage("token", false);
        setSettings({});
        setDialogData({});
    }, [setDialogData, setSettings])
}