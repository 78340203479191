export const autoFontSize = (el) => {
    const overSize = (el) => {
        const w1 = el.clientWidth;
        const h1 = el.clientHeight;
        const w2 = el.parentElement.clientWidth;
        const h2 = el.parentElement.clientHeight;
        if (w1 > w2 || h1 > h2) {
            return true;
        }
    }
    const step = 0.5;
    if (!el || !el.innerText.length) return;
    let font = 0;
    do {el.style.fontSize = `${font = font + step}px`} while (!overSize(el));
    el.style.fontSize = `${font - step}px`;
}
