export const flup = (s) => s && (s => s && s.length > 0 && s[0].toUpperCase() + s.slice(1))(clear(s));
export const clear = (s) => s && s.toLowerCase().replace(/[.,!;:]/g, "").replace(/\s+/g, " ").trim();
export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
export const isAndroid = () => /Android/i.test(window.navigator.userAgent);

export const secondsToTimeFormat = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}