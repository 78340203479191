import MUIMasonry from "@mui/lab/Masonry";
import {useContext} from "react";
import {MainContext} from "../App";
import {Box} from "@mui/material";

export const Masonry = ({children, className}) => {
    const {win} = useContext(MainContext);

    const columns = (win.lg && 4) || (win.md && 3) || (win.sm && 2) || (win.xs && 1);
    const spacing = (win.lg && 4) || (win.md && 3) || (win.sm && 2) || (win.xs && 1);

    return (
        <Box sx={{paddingLeft: spacing, paddingTop: spacing}} className={className}>
            <MUIMasonry columns={columns} spacing={spacing}>
                {children}
            </MUIMasonry>
        </Box>
    )
}