import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {storage} from "../../../common/storage";
import Tooltip from '@mui/material/Tooltip';
import {autoFontSize} from "../../../common/autoFont";
import {MainContext} from "../../../App";
import {VideoContext} from "../video";
import {Loading} from "../../../common/loading";
import {Words} from "./words-area/Words";
import {VocabularyArea} from "./vocabulary-area/vocabulary-area";
import {Buttons} from "./buttons/buttons";
import css from './box.module.css';


export const BoxContext = React.createContext({});

const minWidth = 260;
const minHeight = 50;
const addWidth = 0;
const addHeight = 123;

const getDefSize = (w, h) => {
    let width = w * 0.6;
    if (width < minWidth) width = minWidth;

    let height = h * 0.3;
    if (height < minHeight) height = minHeight;

    return {width, height}
}
const getDefPos = (w, h) => {
    const size = getDefSize(w, h);
    const left = Math.round((w - size.width) / 2);
    const top = Math.round((h - size.height - addHeight) / 2);

    return {left, top}
}
const AutoFontSize = ({children}) => {
    const {size} = useContext(BoxContext);

    const contentRef = useRef();

    useEffect(() => {
        autoFontSize(contentRef.current.firstChild);
    }, [size]);

    return (
        <div style={{...size, opacity: 1}} ref={contentRef}>
            {children}
        </div>
    )
}
const Resize = ({tooltip}) => {
    const {width, height, size, setSize, position, setInProcess} = useContext(BoxContext);
    const resize = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setInProcess(true);

        let startX, startY;
        if ('touches' in e) {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        } else {
            startX = e.clientX;
            startY = e.clientY;
        }

        const handleMouseMove = (moveEvent) => {
            let clientX, clientY;
            if ('touches' in moveEvent) {
                clientX = moveEvent.touches[0].clientX;
                clientY = moveEvent.touches[0].clientY;
            } else {
                clientX = moveEvent.clientX;
                clientY = moveEvent.clientY;
            }

            const movementX = clientX - startX;
            const movementY = clientY - startY;


            setSize((currentSize) => {
                const newSize = {
                    width: currentSize.width + movementX,
                    height: currentSize.height + movementY,
                };

                if (newSize.width < minWidth) newSize.width = minWidth;

                if (newSize.height < minHeight) newSize.height = minHeight;

                const maxWidth = width - position.left - addWidth;
                if (newSize.width > maxWidth) newSize.width = maxWidth;

                const maxHeight = height - position.top - addHeight;
                if (newSize.height > maxHeight) newSize.height = maxHeight;


                saveSize(width, height, newSize);
                return newSize
            });


            startX = clientX;
            startY = clientY;
        };
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchend', handleMouseUp);
            setInProcess(false);
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchend', handleMouseUp);

    }, [position, width, height]);

    const getSize = (w, h) => storage(`${w}-${h}-size`) || getDefSize(w, h);
    const saveSize = (w, h, size) => storage(`${w}-${h}-size`, size);


    useEffect(() => {
        setSize(getSize(width, height));
    }, [width, height]);

    return (
        <Tooltip title={tooltip} arrow placement="right">
            <div className={css.resizer} style={{top: size.height}} onMouseDown={resize} onTouchStart={resize}/>
        </Tooltip>
    );
}
const Reposition = ({children, tooltip}) => {
    const {width, height, size, position, setPosition, setInProcess} = useContext(BoxContext);
    const drag = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setInProcess(true);
        const startX = ('touches' in e ? e.touches[0].clientX : e.clientX) - position.left;
        const startY = ('touches' in e ? e.touches[0].clientY : e.clientY) - position.top;


        const handleMouseMove = (moveEvent) => {
            const clientX = 'touches' in moveEvent ? moveEvent.touches[0].clientX : moveEvent.clientX;
            const clientY = 'touches' in moveEvent ? moveEvent.touches[0].clientY : moveEvent.clientY;

            const newPos = {
                left: clientX - startX,
                top: clientY - startY,
            }

            const minLeft = -1;
            if (newPos.left < minLeft) newPos.left = minLeft;

            const minTop = -1;
            if (newPos.top < minTop) newPos.top = minTop;

            const maxLeft = width - size.width - addWidth;
            if (newPos.left > maxLeft) newPos.left = maxLeft;

            const maxTop = height - size.height - addHeight;
            if (newPos.top > maxTop) newPos.top = maxTop;

            setPosition(newPos);
            savePos(width, height, newPos);

        };
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchend', handleMouseUp);
            setInProcess(false);
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('touchmove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchend', handleMouseUp);
    }, [position, size, width, height]);

    const getPos = (w, h) => storage(`${w}-${h}-position`) || getDefPos(w, h);
    const savePos = (w, h, position) => storage(`${w}-${h}-position`, position);


    useEffect(() => {
        setPosition(getPos(width, height))
    }, [width, height]);

    return (
        <Tooltip title={tooltip} arrow placement="top">
            <div className={css.draggier} onMouseDown={drag} onTouchStart={drag}>
                {children}
            </div>
        </Tooltip>
    );
}
const Main = ({children}) => {
    const {position, inProcess} = useContext(BoxContext);
    const {captions} = useContext(VideoContext);

    return (
        <div className={`${css.resizableDialog} ${inProcess ? css.inProcess : ""}`} style={position}>
            <Reposition tooltip="Drag dialog"/>
            {children}
            <Resize tooltip="Resize"/>
            {!captions && <div className={css.loading} style={{marginTop: -minHeight}}><Loading/></div>}
            <div className={css.inProcessBack}/>
            <div className={css.back}/>
        </div>
    )
}

export const Box = () => {
    const {windowSize: {width, height}} = useContext(MainContext);

    const [inProcess, setInProcess] = useState(false);
    const [size, setSize] = useState({});
    const [position, setPosition] = useState({});

    const value = {width, height, size, setSize, position, setPosition, setInProcess, inProcess};

    return (
        <BoxContext.Provider value={value}>
            <Main>
                <AutoFontSize>
                    <Words/>
                </AutoFontSize>
                <VocabularyArea/>
                <Buttons/>
            </Main>
        </BoxContext.Provider>
    )
}
