import React, {useContext, useEffect, useRef, useState} from "react";
import {autoFontSize} from "../../../../common/autoFont";
import {VideoContext} from "../../video";
import {Server} from "../../../../common/server";
import {MainContext} from "../../../../App";
import css from "./words-area.module.css";


const Word = ({text, selected, onClick}) => {
    return (
        <span className={`${css.word} ${selected ? css.selected : ""}`} onClick={onClick}>
            {text}
        </span>
    )
}

export const Words = () => {
    const {settings: {lang, myLang}} = useContext(MainContext);
    const {setText, setTrans, captions, index, player} = useContext(VideoContext);
    const [selected, setSelected] = useState({});
    const [words, setWords] = useState([]);
    const wordsAreaRef = useRef();

    const onWordClick = (i) => {
        if ([1, 2].includes(player.playerInfo.playerState)) {
            player.pauseVideo();
            player.seekTo(captions[index][0] / 1000);
        }

        selected[i] = !selected[i];
        setSelected(selected);

        const text = words.reduce((a, c, i) => `${a}${selected[i] ? ` ${c}` : ""}`, "").replace(/[.,!;:]/g, "").trim();
        setText(text);
        setTrans("");
        Server.translate(lang, myLang, text).then(setTrans);
    }

    useEffect(() => {
        setWords(captions && captions[index] ? captions[index][1].split(" ") : []);
    }, [captions, index]);


    useEffect(() => {
        autoFontSize(wordsAreaRef.current);
        setSelected({});
        setText("");
        setTrans("");
    }, [words]);


    return (
        <div ref={wordsAreaRef} className={css.words}>
            {words.map((word, i) => (
                <Word key={i} selected={selected[i]} text={word} onClick={() => onWordClick(i)}/>
            ))}
        </div>
    )
}
