import React, {useContext, useState} from "react";
import TranslateIcon from '@mui/icons-material/Translate';
import {Button, ButtonGroup, Tooltip} from "@mui/material";
import {speak} from "../../../../common/speak";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import {VideoContext} from "../../video";
import {MainContext} from "../../../../App";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import {Vocabulary} from "../../../vocabulary/vocabulary";
import {EditVocabulary} from "../../../vocabulary/edit-vocabulary/edit-vocabulary";
import css from "./vocabulary-area.module.css"


const GT = () => {
    const {text} = useContext(VideoContext);
    const {settings: {lang, myLang}} = useContext(MainContext);

    const onClick = () => window.open(`https://translate.google.com/?sl=${lang}&tl=${myLang}&text=${text}`, "google");

    return text && (
        <Tooltip title="Google Translate" arrow placement="top">
            <TranslateIcon onClick={onClick}/>
        </Tooltip>
    )
}
const Speak = () => {
    const {text} = useContext(VideoContext);
    const {settings: {lang}} = useContext(MainContext);

    return text && (
        <Tooltip title="Listen" arrow placement="top">
            <VolumeUpIcon onClick={() => speak(lang, text)}/>
        </Tooltip>
    )
}
const OpenVocabulary = () => {
    const {setDialogData} = useContext(MainContext);

    const onClick = () => setDialogData({
        label: "Vocabulary",
        Icon: LocalLibraryIcon,
        Component: Vocabulary,
        size: "xl"
    })

    return (
        <Tooltip title="Open vocabulary" arrow placement="top">
            <Button variant={"contained"} style={{minWidth: 0}} onClick={onClick}>
                <LocalLibraryIcon/>
            </Button>
        </Tooltip>
    )
}
const TransArea = () => {
    const {text, trans} = useContext(VideoContext);

    return (
        <div className={css.transArea}>
            <GT/>
            <Speak/>
            <b>{text}</b>
            <i>{trans}</i>
        </div>
    )
}

export const AddToVocabulary = () => {
    const {text, trans} = useContext(VideoContext);
    const [open, setOpen] = useState(false);

    return (
        <>
            <EditVocabulary text={text} trans={trans} open={open} onClose={() => setOpen(false)}/>
            <Tooltip title="Add to vocabulary" arrow placement="top">
                <Button variant={"contained"} style={{minWidth: 0}} onClick={() => setOpen(true)}>
                    <LibraryAddIcon/>
                </Button>
            </Tooltip>
        </>
    )
}
export const VocabularyArea = () => {
    return (
        <div className={css.vocabularyArea}>
            <TransArea/>
            <ButtonGroup>
                <AddToVocabulary/>
                <OpenVocabulary/>
            </ButtonGroup>
        </div>
    )
}
