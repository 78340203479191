import React, {useEffect, useState} from "react";
import "./App.css";
import Router from "./router";
import {Server} from "./common/server";

export const MainContext = React.createContext({});

const App = () => {
    const [windowSize, setWindowSize] = useState({width: window.innerWidth, height: window.innerHeight});
    const [settings, setSettings] = useState(null);
    const [dialogData, setDialogData] = useState({});
    const [homeData, setHomeData] = useState({});
    const [prevURL, setPrevURL] = useState("");
    const [win, setWin] = useState({});

    useEffect(() => {
        const onResize = () => {
            setTimeout(() => {
                setWindowSize({width: window.innerWidth, height: window.innerHeight})
            }, 300)
        }
        window.addEventListener("resize", onResize);
        Server.getSettings().then(setSettings);
        return () => window.removeEventListener("resize", onResize);
    }, []);
    useEffect(() => {
        const {width} = windowSize;
        setWin({
            xs: width >= 0,
            sm: width >= 600,
            md: width >= 960,
            lg: width >= 1280,
            xl: width >= 1920,
        });
    }, [windowSize])

    const value = {prevURL, setPrevURL, win, homeData, setHomeData, setDialogData, setSettings, dialogData, settings, windowSize};

    return (
        <MainContext.Provider value={value}>
            <Router/>
        </MainContext.Provider>
    )
}

export default App;
