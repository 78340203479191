import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {BarChart, LineChart} from "@mui/x-charts";
import {Server} from "../../common/server";
import {Loading} from "../../common/loading";
import {MainContext} from "../../App";
import css from "./dashboard.module.css";
import {HomeContext} from "../home/home";

const dateCompare = (a, date) => a.filter(a => {
    return a.date <= date
}).length;
const dt = (d) => +(d => new Date(d.getFullYear(), d.getMonth(), d.getDate()))(new Date(d));

export const Dashboard = () => {
    const {appBarHeight} = useContext(HomeContext);
    const {windowSize: {height}} = useContext(MainContext);
    const [dates, setDates] = useState([]);
    const [vocabularyData, setVocabularyData] = useState([]);
    const [learningData, setLearningData] = useState([]);
    const [learnedData, setLearnedData] = useState([]);
    const [newWordsData, setNewWordsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        (async () => {
            const settings = await Server.getSettings();
            const scores = await Server.readFromScores();
            const vocabulary = await Server.readFromVocabulary();


            const vocabularyData = [];
            const learningData = [];
            const learnedData = [];
            const newWordsData = [];
            const dates = [...new Set([dt(settings.created), dt(new Date()), ...scores.map(s => dt(s.date)), ...vocabulary.map(s => dt(s.date))])].sort().map(date=>new Date(date));

            dates.forEach(date => {
                const scoresInDate = scores.filter(s => dt(s.date) <= date);

                const L = {};

                scoresInDate.map(({item: {text, trans}, date}) => ({date, id: [text, trans].sort().join("=")}))
                    .map(({date, id}, i, a) => {
                        const count = a.filter(l => l.id === id).length;
                        L[count] = L[count] || {};
                        L[count][id] = true;
                        return {date, id, count}
                    })

                const N = Object.entries(L).map(([status, item]) => ({status, count: Object.keys(item).length}));

                const voc = dateCompare(vocabulary, date);
                let learning = 0;
                let learned = 0;

                N.forEach(({status, count}) => {
                    if (status < 5) {
                        learning = learning + count;
                    } else {
                        learned = learned + count;
                    }
                })

                learningData.push(learning);
                learnedData.push(learned);
                newWordsData.push(voc-learning-learned);
                vocabularyData.push(voc);
            });

            setDates(dates);
            setLearningData(learningData);
            setNewWordsData(newWordsData);
            setLearnedData(learnedData);
            setVocabularyData(vocabularyData);

            setIsLoading(false);
        })()
    }, []);

    if (isLoading) return <Loading/>;


    return (
        <div className={css.dashboard}>
            <LineChart
                height={height - appBarHeight}
                series={[
                    {data: vocabularyData, label: 'Words in vocabulary', color: "#ff9100"},
                    {data: newWordsData, label: 'New words', color: "#00e676"},
                    {data: learningData, label: 'Learning words', color: "#2979ff"},
                    {data: learnedData, label: 'Learned words', color: "#f50057"},
                ]}
                xAxis={[{scaleType: "time", data: dates}]}
            />
            <BarChart
                xAxis={[
                    {
                        scaleType: 'band',
                        data: [''],
                        categoryGapRatio: 0.3,
                        barGapRatio: 0.1,
                    }]}
                dataset={[{
                    vocabulary: vocabularyData[vocabularyData.length-1],
                    learning: learningData[learningData.length-1],
                    learned: learnedData[learnedData.length-1],
                    newWords: newWordsData[newWordsData.length-1],
                }]}
                series={[
                    {dataKey: "vocabulary", label: "Words in vocabulary"},
                    {dataKey: "newWords", label: "New words"},
                    {dataKey: "learning", label: "Learning words"},
                    {dataKey: "learned", label: "Learned words"},
                ]}
                height={height - appBarHeight}
            />
        </div>
    );
}