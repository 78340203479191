import React, {useEffect} from "react";
import {InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";


export const Line = ({title, value, options, onChange, disabled}) => {
    const [nValue, setValue] = React.useState(value);
    const [nOptions, setNOptions] = React.useState(options);

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
    };

    useEffect(() => {
        setValue(value)
    }, [value])

    useEffect(() => {
        setNOptions(options)
    }, [options])

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <InputLabel style={{marginBottom: 8}}>{title}</InputLabel>
            <Select disabled={disabled} value={nValue} onChange={handleChange}>
                {nOptions.map(({title, value}, i) => (
                    <MenuItem key={i} value={value}>
                        {title}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
