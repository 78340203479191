import * as React from 'react';
import {useContext} from 'react';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {MainContext} from "../../../App";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MUIDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {HomeContext} from "../home";
import {Chip} from "@mui/material";
import css from "./drawer.module.css";

const UserToolbar = () => {
    const {settings: {user}} = useContext(MainContext);
    const {setDrawerOpen} = useContext(HomeContext);

    return (
        <Toolbar style={{paddingLeft: 0}}>
            <IconButton onClick={() => setDrawerOpen(false)}>
                <ChevronLeftIcon/>
            </IconButton>
            <Chip className={css.userChip} icon={<AccountCircle/>} label={user}/>
        </Toolbar>
    )
}
const ItemsList = ({items}) => {
    const {setDialogData, setHomeData, homeData: {url}, settings: {isAdmin}} = useContext(MainContext);
    const {setDrawerOpen} = useContext(HomeContext);

    const onClick = (item) => {
        item.url ? setHomeData(item) : setDialogData(item);
        setDrawerOpen(false);
    }

    return (
        <List>
            {items.filter(item => !item.disabled && (isAdmin || !item.admin)).map(({Icon, ...item}, i) => {
                return (
                    <ListItem selected={item.url === url} key={i} disablePadding>
                        <ListItemButton onClick={() => onClick(item)}>
                            <ListItemIcon><Icon/></ListItemIcon>
                            <ListItemText primary={item.label}/>
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    )
}
export const Drawer = () => {
    const {drawerOpen, setDrawerOpen, items, menu, drawerWidth} = useContext(HomeContext);
    const {win} = useContext(MainContext);

    return (win.md ? (
        <MUIDrawer className={css.drawer} style={{width: drawerWidth}} variant="permanent">
            <Toolbar/>
            <ItemsList items={items}/>
            <Divider/>
        </MUIDrawer>
    ) : (
        <SwipeableDrawer className={css.drawer} style={{width: drawerWidth}} open={drawerOpen} onClose={() => setDrawerOpen(false)} onOpen={() => setDrawerOpen(true)}>
            <UserToolbar/>
            <Divider/>
            <ItemsList items={items}/>
            <Divider/>
            <ItemsList items={menu}/>
        </SwipeableDrawer>
    ))

}


