import React, {useContext, useEffect, useState} from "react";
import {MainContext} from "../../App";
import {Drawer} from "./drawer/drawer";
import {Loading} from "../../common/loading";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import HistoryIcon from "@mui/icons-material/History";
import History from "../history/history";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import {Vocabulary} from "../vocabulary/vocabulary";
import APS from "@mui/icons-material/AdminPanelSettings";
import {Admin} from "../admin/admin";
import SettingsIcon from "@mui/icons-material/Settings";
import {Settings} from "../../common/settings";
import LogoutIcon from "@mui/icons-material/Logout";
import {Logout} from "../../common/logout";
import {Search} from "../search/search";
import {useNavigate, useParams} from "react-router-dom";
import {AppBar} from "./app-bar/app-bar";
import {Container} from "./container/container";
import CategoryIcon from '@mui/icons-material/Category';
import {Training} from "../training/training";
import {Dashboard} from "../dashboard/dashboard";
import AssessmentIcon from '@mui/icons-material/Assessment';
import PaidIcon from '@mui/icons-material/Paid';
import css from "./home.module.css";
import {Pay} from "../pay/pay";


export const HomeContext = React.createContext({});

const appBarHeight = 64;
const drawerWidth = 240;
const items = [
    {label: "Dashboard", Icon: AssessmentIcon, Component: Dashboard, url: "/dashboard/"},
    {label: "Video search", Icon: VideoLibraryIcon, Component: Search, url: "/search/"},
    {label: "History", Icon: HistoryIcon, Component: History, url: "/history/"},
    {label: "Vocabulary", Icon: LocalLibraryIcon, Component: Vocabulary, url: "/vocabulary/"},
    {label: "Training", Icon: CategoryIcon, Component: Training, url: "/training/"},
    {label: "Admin", Icon: APS, Component: Admin, url: "/admin/", admin: true},
    {label: "Pay", Icon: PaidIcon, Component: Pay, url: "/pay/", admin: true},
]
const menu = [
    {label: "Settings", Icon: SettingsIcon, Component: Settings, size: "xs", minHeight: true},
    {label: "Logout", Icon: LogoutIcon, Component: Logout, size: "none"},
]

export const Home = () => {
    const navigate = useNavigate();
    const {pageId} = useParams();
    const [searchItem, setSearchItem] = useState({});

    const [data, setData] = useState({items: false});
    const [isLoading, setIsLoading] = useState(false);

    const {homeData: {Component, url}, setHomeData} = useContext(MainContext);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        const item = items.find(item => item.url === `/${pageId}/`);
        item ? setHomeData(item) : setHomeData(items[0])
    }, [pageId, setHomeData])

    useEffect(() => {
        navigate(url);
    }, [Component, navigate, url])

    if (!Component) return <Loading/>;

    const USI = k => v => setSearchItem((s) => ({...s, [Object.keys(k)[0]]: v}));

    const value = {USI, searchItem, setSearchItem, appBarHeight, setData, setHomeData, setIsLoading, isLoading, data, items, menu, drawerOpen, setDrawerOpen, drawerWidth};

    return (
        <HomeContext.Provider value={value}>
            <div className={css.home}>
                <AppBar/>
                <Drawer/>
                <Container/>
            </div>
        </HomeContext.Provider>
    )
}

