import React, {useContext, useEffect, useState} from "react";
import {Youtube} from "./youtube/youtube";
import {Box} from "./box/box";
import {useParams} from "react-router-dom";
import {Server} from "../../common/server";
import {MainContext} from "../../App";
import {VideoHeader} from "./video-header/video-header";

export const VideoContext = React.createContext({});

export const Video = () => {
    const {videoId} = useParams();
    const {setDialogData, settings: {lang}} = useContext(MainContext);

    const [player, setPlayer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [captions, setCaptions] = useState(null);
    const [index, setIndex] = useState(0);

    const [text, setText] = useState("");
    const [trans, setTrans] = useState("");

    useEffect(() => {
        document.title = videoId;

        setDialogData({});
        setIsPlaying(false);
        setCaptions(null);
        setIndex(0);
        setText("");
        setTrans("");

        Server.getCaptions(videoId, lang).then(setCaptions);
    }, [videoId, lang]);

    const value = {setIndex, setText, setTrans, setPlayer, setIsPlaying, videoId, trans, text, isPlaying, player, captions, index};

    return (
        <VideoContext.Provider value={value}>
            <VideoHeader/>
            <Youtube/>
            <Box/>
        </VideoContext.Provider>
    )
}
