import React, {useContext, useEffect, useRef, useState} from "react";
import MultiActionAreaCard from "../../common/card";
import {Masonry} from "../../common/masonry";
import {Loading} from "../../common/loading";
import {HomeContext} from "../home/home";
import {Server} from "../../common/server";
import {MainContext} from "../../App";
import {Jumbotron} from "../../common/jumbotron";
import css from "./search.module.css";

const Items = () => {
    const {data, isLoading} = useContext(HomeContext);

    if (isLoading) return <Loading/>;
    if (data.items && !data.items.length) return <Jumbotron>No results...</Jumbotron>;

    return (
        <Masonry>
            {(data.items || []).map((item, i) => (
                <MultiActionAreaCard key={i} item={item}/>
            ))}
        </Masonry>
    )
}

export const Search = () => {
    const {settings: {lang}} = useContext(MainContext);
    const {data, setData, searchItem, isLoading} = useContext(HomeContext);
    const [loading, setLoading] = useState(false);
    const [intersected, setIntersected] = useState(0);
    const loaderRef = useRef();


    useEffect(() => {
        (async () => {
            if (loading) return;
            if (isLoading) return;

            const {chartPageToken} = data.data || {};
            const {searchPageToken} = data.data || {};

            if (!chartPageToken && !searchPageToken) return;

            setLoading(true);
            const newData = await Server.searchVideos(searchItem, lang, searchItem.address, searchPageToken, chartPageToken);
            newData.items = [...data.items, ...newData.items];
            setData(newData);
            setLoading(false);
        })()
    }, [intersected]);

    useEffect(() => {
        const observer = new IntersectionObserver((e) => {
            e[0].isIntersecting && setIntersected(+new Date())
        }, {root: null, rootMargin: "20px", threshold: 1.0});
        observer.observe(loaderRef.current);
    }, []);

    return (
        <div className={css.search}>
            <Items/>
            <div ref={loaderRef}>{loading && <Loading/>}</div>
        </div>
    )
}

