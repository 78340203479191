import React, {useContext} from "react";
import {MainContext} from "../../../App";
import {HomeContext} from "../home";
import css from "./container.module.css";

export const Container = () => {
    const {appBarHeight} = useContext(HomeContext);
    const {windowSize: {height}, homeData: {Component}} = useContext(MainContext);

    return (
        <div className={css.container} style={{marginTop: appBarHeight, height: height - appBarHeight}}>
            <Component/>
        </div>
    )
}