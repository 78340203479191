import React, {useEffect, useState} from "react";
import {Server} from "../../common/server";
import MultiActionAreaCard from "../../common/card";
import {Masonry} from "../../common/masonry";
import {Loading} from "../../common/loading";
import {Jumbotron} from "../../common/jumbotron";
import css from "./history.module.css";

const History = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        Server.readFromHistory().then(items=>setIsLoading(setItems(items)));
    }, [])

    const ids = [...new Set(items.map(item => item.id))];

    let nItems = items.reverse();
    nItems = ids.map(id => ([id, nItems.filter(item => item.id === id)]));
    nItems = nItems.sort((a, b) => b[1][0].viewed - a[1][0].viewed);
    nItems = nItems.map(([, items]) => ([items[0], items.map(item => item.viewed)]));
    nItems = nItems.map(([item, views]) => ([item, views[0], views.length]));

    if(isLoading) return <Loading/>
    if(!items.length) return <Jumbotron>No results...</Jumbotron>


    return (
        <Masonry className={css.history}>
            {nItems.map(([item, viewed, count], i) => (
                <div key={i}>
                    <h3>
                        {new Date(viewed).toString().split(" ").slice(0, 5).join(" ")}
                    </h3>
                    <MultiActionAreaCard key={i} item={item} fontSize={12}/>
                </div>
            ))}
        </Masonry>
    )
}

export default History;