import * as React from "react";
import {useContext, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import {MainContext} from "../../../App";
import {LoadingButton} from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import {HomeContext} from "../home";
import {Server} from "../../../common/server";
import TranslateIcon from "@mui/icons-material/Translate";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MUIAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Line} from "../../../common/line";
import {categories, orders, videoDurations} from "../../../common/categories";
import TuneIcon from '@mui/icons-material/Tune';
import css from "./app-bar.module.css";
import MUIAutocomplete from "@mui/material/Autocomplete";


const AppbarContext = React.createContext({});

const Autocomplete = ({disabled, defValue, Input, label, onChange}) => {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState("");

    const handleInputChange = (event, term) => {
        onChange(term);
        setValue(term);
        Server.autocomplete(term).then(setOptions);
    };

    useEffect(() => {
        setValue(defValue);
    }, [defValue])

    return (
        <MUIAutocomplete
            disabled={disabled}
            freeSolo
            inputValue={value}
            onInputChange={handleInputChange}
            options={options}
            renderInput={(params) => <Input {...params} label={label}/>}
        />
    );
};
const Input = ({label, value, onChange, ...props}) => {
    return (
        <TextField
            {...props}
            variant={"filled"}
            size="small"
            label={label}
            onChange={(e) => onChange && onChange(e.target.value)}
            value={value}
        />
    )
}
const SubmitButton = ({loading, label}) => {
    const {win} = useContext(MainContext);

    return (
        <LoadingButton variant="contained" loading={loading} startIcon={<SearchIcon/>} type="submit">
            {win.md && label}
        </LoadingButton>
    )
}
const FromEX = () => {
    const {isLoading, searchItem, USI} = useContext(HomeContext);
    const [anchorFX, setAnchorFX] = React.useState(null);

    const {videoCategoryId = 0, order = "relevance", videoDuration = "any", address = ""} = searchItem;
    return (
        <>
            <TuneIcon style={{cursor: "pointer"}} onClick={e => setAnchorFX(e.currentTarget)}/>
            <Menu transformOrigin={{vertical: "top", horizontal: "right"}} anchorEl={anchorFX} open={!!anchorFX} onClose={() => setAnchorFX(null)}>
                <div className={css.formExContainer}>
                    <Autocomplete disabled={isLoading} defValue={address} Input={Input} onChange={USI({address})} label="City"/>
                    <div className={css.formEx}>
                        <Line disabled={isLoading} options={categories} title={"Categories"} value={videoCategoryId} onChange={USI({videoCategoryId})}/>
                        <Line disabled={isLoading} options={videoDurations} title={"Duration"} value={videoDuration} onChange={USI({videoDuration})}/>
                        <Line disabled={isLoading} options={orders} title={"Order by"} value={order} onChange={USI({order})}/>
                    </div>
                </div>
            </Menu>
        </>
    )
}
const Form = () => {
    const {
        items,
        setData,
        setHomeData,
        setIsLoading,
        isLoading,
        searchItem,
        setSearchItem,
        USI
    } = useContext(HomeContext);
    const {settings: {lang}} = useContext(MainContext);

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setHomeData(items.find(item => item.url === `/search/`));
        setData(await Server.searchVideos(searchItem, lang, address));
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const {data, ...item} = await Server.getSearchCache();
            setSearchItem(item);
            data && setData(data);
            setIsLoading(false);
        })()
    }, []);

    const {q = "", address = ""} = searchItem;

    return (
        <div className={css.form}>
            <form onSubmit={onSubmit}>
                <Input disabled={isLoading} value={q} onChange={USI({q})} label="Search"/>
                <FromEX/>
                <SubmitButton loading={isLoading} label="Search"/>
            </form>
        </div>
    )
}
const Logo = () => {
    const {win} = useContext(MainContext);

    return win.md && (
        <div className={css.logo}>
            <a href="/">
                <div>
                    <TranslateIcon/> Lang.
                </div>
            </a>
        </div>

    )
}
const DrawerOpenIcon = () => {
    const {win} = useContext(MainContext);
    const {setDrawerOpen} = useContext(HomeContext);

    return !win.md && (
        <IconButton onClick={() => setDrawerOpen(true)} size="large" edge="start" color="inherit">
            <MenuIcon/>
        </IconButton>
    )
}
const AccountOpenIcon = () => {
    const {win, settings: {user}} = useContext(MainContext);
    const {setAnchorEl} = useContext(AppbarContext);


    return win.md && (
        <div className={css.accountOpenIcon}>
            {user}
            <IconButton onClick={e => setAnchorEl(e.currentTarget)} size="large" edge="end" color="inherit">
                <AccountCircle/>
            </IconButton>
            <AccountMenu/>
        </div>
    )
}
const MenuItems = () => {
    const {menu} = useContext(HomeContext);
    const {setDialogData} = useContext(MainContext);
    const {setAnchorEl} = useContext(AppbarContext);

    return (
        <>
            {menu.map(({Icon, ...item}, i) => (
                <MenuItem key={i} onClick={() => setDialogData(item) || setAnchorEl(null)}>
                    <ListItemIcon><Icon/></ListItemIcon>
                    <ListItemText>{item.label}</ListItemText>
                </MenuItem>
            ))}
        </>
    )
}
const AccountMenu = () => {
    const {anchorEl, setAnchorEl} = useContext(AppbarContext);

    return (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
            <MenuItems/>
        </Menu>
    )
}
export const AppBar = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {appBarHeight} = useContext(HomeContext);

    const value = {anchorEl, setAnchorEl};

    return (
        <AppbarContext.Provider value={value}>
            <div className={css.appBar}>
                <MUIAppBar style={{height: appBarHeight}}>
                    <Toolbar>
                        <Logo/>
                        <DrawerOpenIcon/>
                        <Form/>
                        <AccountOpenIcon/>
                    </Toolbar>
                </MUIAppBar>
            </div>
        </AppbarContext.Provider>
    )
}