export const speak = (lang, text) => {
    window.speechSynthesis.cancel();
    const utterance = new SpeechSynthesisUtterance();
    utterance.text = text;
    utterance.lang = lang;
    setTimeout(() => window.speechSynthesis.speak(utterance), 100)
};

export const Rec = (lang, sendStatus, sendText) => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) return sendStatus("error");

    const recognition = new SpeechRecognition();
    recognition.lang = lang;
    recognition.interimResults = false;
    recognition.onaudiostart = () => sendStatus("audiostart");
    recognition.onaudioend = () => sendStatus("audioend");
    recognition.onstart = () => sendStatus("start");
    recognition.onend = () => sendStatus("end") || recognition.start();
    recognition.onsoundstart = () => sendStatus("soundstart");
    recognition.onsoundend = () => sendStatus("soundend");
    recognition.onspeechstart = () => sendStatus("speechstart");
    recognition.onspeechend = () => sendStatus("speechend");
    recognition.onerror = () => sendStatus("error");
    recognition.onnomatch = () => sendStatus("nomatch");
    recognition.onresult = e => {
        if (e.results[0].isFinal) {
            sendText(Array.from(e.results).map(result => result[0]).map(result => result.transcript).join(""));
        }
    };
    recognition.start();

    const destroy = () => {
        recognition.stop();
        recognition.lang = null;
        recognition.interimResults = null;
        recognition.onaudiostart = null;
        recognition.onaudioend = null;
        recognition.onstart = null;
        recognition.onend = null;
        recognition.onsoundstart = null;
        recognition.onsoundend = null;
        recognition.onspeechstart = null;
        recognition.onspeechend = null;
        recognition.onerror = null;
        recognition.onnomatch = null;
        recognition.onresult = null;
    }

    return {destroy};
}

