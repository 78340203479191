import React, {useContext} from "react";
import {Video} from "./pages/video/video";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Loading} from "./common/loading";
import Login from "./pages/login/Login";
import {MainContext} from "./App";
import {Dialog} from "./pages/dialog/dialog";
import {Home} from "./pages/home/home";

const Router = () => {
    const {settings} = useContext(MainContext);

    if (!settings) return <Loading/>;
    if (!settings.success) return <Login/>;

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/video/:videoId" element={<Video/>}/>
                <Route path="/:pageId" element={<Home/>}/>
                <Route path="/" element={<Home/>}/>
            </Routes>
            <Dialog/>
        </BrowserRouter>
    )
}

export default Router;
