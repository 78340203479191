import React, {useContext} from "react";
import MUIDialog from "@mui/material/Dialog";
import {DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {MainContext} from "../../App";
import css from "./dialog.module.css";

export const Dialog = () => {
    const {dialogData: {label, Component, size, minHeight}, setDialogData} = useContext(MainContext);
    const onClose = () => setDialogData({});

    if (!Component) return;
    if (size === "none") return <Component/>;

    return (
        <MUIDialog maxWidth={size} fullWidth onClose={onClose} open={true} className={!minHeight ? css.maxHeight : ""}>
            <DialogTitle sx={{m: 0, p: 2}}>{label}</DialogTitle>
            <IconButton onClick={onClose}><CloseIcon/></IconButton>
            <DialogContent dividers>
                <Component onClose={onClose}/>
            </DialogContent>
        </MUIDialog>
    )
}
