import {useContext, useEffect, useRef, useState} from "react";
import {Rec} from "../../../common/speak";
import {Loading} from "../../../common/loading";
import {clear, isAndroid} from "../../../common/flup";
import MicIcon from "@mui/icons-material/Mic";
import {LinearProgress} from "@mui/material";
import {TrainingContext} from "../training";
import css from "./recognize.module.css";

const Visualization = () => {
    const canvasRef = useRef();

    useEffect(() => {
        let audioCtx, analyser, source, stream;

        const destroy = async () => {
            source && source.disconnect(analyser);
            stream && stream.getTracks()[0].stop();
            audioCtx && await audioCtx.close();
        }

        (async () => {
            const draw = () => {
                requestAnimationFrame(draw);
                analyser.getByteTimeDomainData(dataArray);

                canvasCtx.fillStyle = 'rgb(255, 255, 255)';
                canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
                canvasCtx.beginPath();

                let sliceWidth = canvas.width * 1.0 / bufferLength;
                let x = 0;

                for (let i = 0; i < bufferLength; i++) {
                    let v = dataArray[i] / 128.0;
                    let y = v * canvas.height / 2;

                    if (i === 0) {
                        canvasCtx.moveTo(x, y);
                    } else {
                        canvasCtx.lineTo(x, y);
                    }

                    x += sliceWidth;
                }

                canvasCtx.lineTo(canvas.width, canvas.height / 2);
                canvasCtx.stroke();
            }

            if (!navigator.mediaDevices) return;

            const canvas = canvasRef.current;
            const canvasCtx = canvas.getContext("2d");
            stream = await window.navigator.mediaDevices.getUserMedia({audio: true});
            audioCtx = new (window.AudioContext || window.webkitAudioContext)();
            analyser = audioCtx.createAnalyser();
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            source = audioCtx.createMediaStreamSource(stream);
            source.connect(analyser);

            draw();
        })();
        return destroy
    }, []);

    return (
        <canvas ref={canvasRef} className={css.visualisation}/>
    )
}
export const Recognize = () => {
    const {text, myLang, setAnswer, setTypeWithout} = useContext(TrainingContext);
    const [speaking, setSpeaking] = useState(false);
    const [status, setStatus] = useState("");
    const [started, setStarted] = useState(false);
    const [recognized, setRecognized] = useState("");

    useEffect(() => {
        setStatus("");
        setRecognized("");
        if (clear(text).includes(" ")) setTypeWithout(Recognize);
    }, [text]);
    useEffect(() => {
        const {destroy} = Rec(myLang, setStatus, setRecognized);
        return () => destroy()
    }, [myLang]);
    useEffect(() => {
        if (recognized && status === "end") setAnswer(recognized);
        if (status === "audiostart") setStarted(true);
        if (status === "error") setTypeWithout(Recognize);
        setSpeaking(status === "speechstart");
    }, [recognized, status])

    if (!started) return <Loading/>;

    const android = isAndroid();

    return (
        <div className={css.recognize}>
            <MicIcon/>
            {!android && <Visualization/>}
            <div>
                <LinearProgress color={speaking ? "secondary" : "primary"}/>
                <LinearProgress color={speaking ? "secondary" : "primary"}/>
                <LinearProgress color={speaking ? "secondary" : "primary"}/>
            </div>
        </div>
    )
}
