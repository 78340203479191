import React, {createContext, useContext, useEffect, useState} from "react";
import {Loading} from "../../common/loading";
import {Server} from "../../common/server";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {clear, flup, getRandomInt, secondsToTimeFormat} from "../../common/flup";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import {Button, ButtonGroup, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {Recognize} from "./recognize/recognize";
import {InputWord} from "./input-word/input-word";
import {Test} from "./test/test";
import {Jumbotron} from "../../common/jumbotron";
import css from "./training.module.css";


export const TrainingContext = createContext({});

const HintButton = () => {
    const {setIsHinted, trans} = useContext(TrainingContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (e) => {
        setIsHinted(true);
        setAnchorEl(anchorEl ? null : e.currentTarget);
    }

    return (
        <>
            <Popper placement="top" open={!!anchorEl} anchorEl={anchorEl}>
                <Paper>
                    <Card>
                        <IconButton style={{position: "absolute", right: 4, top: 4}} onClick={handleClick}><CloseIcon/></IconButton>
                        <CardContent>
                            <h2 style={{marginBottom: 0}}>{flup(trans)}</h2>
                        </CardContent>
                    </Card>
                </Paper>
            </Popper>
            <Button onClick={handleClick}>Hint</Button>
        </>
    )
}
const Buttons = () => {
    const {next, isOk} = useContext(TrainingContext);
    return (
        <ButtonGroup disabled={isOk}>
            <HintButton/>
            <Button onClick={next}>Skip</Button>
        </ButtonGroup>
    )
}
const Text = () => {
    const {text} = useContext(TrainingContext);

    return (
        <h1>
            {flup(text)}
        </h1>
    )
}
const Answered = () => {
    const {isOk, answer} = useContext(TrainingContext);

    const answerText = flup(answer);
    const color = isOk ? "green" : "red";
    const icon = isOk ? <CheckIcon/> : <CloseIcon/>;

    return (
        <h1 style={{color, display: "flex", alignItems: "center"}}>
            &nbsp;
            {answerText && icon}
            {answerText}
        </h1>
    )
}
const Timer = () => {
    const {time} = useContext(TrainingContext);
    return (
        <div>
            {secondsToTimeFormat(time)}
        </div>
    )
}

const TrainingTypes = [Recognize, InputWord, Test];

const TrainingContainer = () => {
    const {type} = useContext(TrainingContext);
    const TrainingType = TrainingTypes[type];

    return (
        <div style={{margin: 16}}>
            <TrainingType/>
        </div>
    )
}

export const Training = () => {
    const [type, setType] = useState(0);
    const [items, setItems] = useState(null);
    const [isOk, setIsOk] = useState(false);
    const [isHinted, setIsHinted] = useState(false);
    const [answer, setAnswer] = useState("");
    const [time, setTime] = useState(0);
    const [lang, seLang] = useState("");
    const [text, setText] = useState("");
    const [myLang, setMyLang] = useState("");
    const [trans, setTrans] = useState("");
    const [words, setWords] = useState([]);

    const setTypeWithout = (component) => {
        const type = getRandomInt(0, TrainingTypes.length - 1);
        if (component === TrainingTypes[type]) return setTypeWithout(component);
        setType(type);
    }

    const next = () => {
        if (!items || !items.length) return;

        const index = getRandomInt(0, items.length - 1);
        const revert = getRandomInt(0, 1);

        const [[lang, text], [myLang, trans]] = (i => revert ? i.reverse() : i)(Object.entries(items[index]).slice(0, 2));
        const words = items.map(item => item[myLang]);


        setTime(0);
        seLang(lang);
        setText(text);
        setMyLang(myLang);
        setTrans(trans);
        setWords(words);
        setIsOk(false);
        setAnswer("");
        setIsHinted(false);
        setTypeWithout();
    }

    useEffect(() => {
        Server.saveToScores().then(setItems);
        const interval = setInterval(() => setTime(time => time + 1), 1000);
        return () => clearInterval(interval);
    }, [])
    useEffect(next, [items])
    useEffect(() => {
        if (answer && trans && clear(trans) === clear(answer)) {
            setIsOk(true);
            setTimeout(() => {
                isHinted ? next() : Server.saveToScores({lang, text, myLang, trans}, +new Date(), time).then(setItems)
            }, 1000)
        }
    }, [answer])


    const value = {
        setTypeWithout,
        type,
        answer,
        next,
        text,
        trans,
        lang,
        myLang,
        words,
        isOk,
        setTime,
        time,
        setAnswer,
        setIsHinted
    };

    if (!items) return <Loading/>;
    if (!items.length) return <Jumbotron>There are no words for training in the vocabulary</Jumbotron>

    return (
        <TrainingContext.Provider value={value}>
            <div className={css.training}>
                <Card>
                    <CardContent>
                        <Timer/>
                        <Text/>
                        <Answered/>
                        <TrainingContainer/>
                        <Buttons/>
                    </CardContent>
                </Card>
            </div>
        </TrainingContext.Provider>
    )
}