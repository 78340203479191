import {useContext, useEffect, useState} from "react";
import {TrainingContext} from "../training";
import {clear, flup, getRandomInt, shuffleArray} from "../../../common/flup";
import {Button} from "@mui/material";
import css from "./test.module.css";


export const Test = () => {
    const {setAnswer, words, trans, text, setIsHinted} = useContext(TrainingContext);

    const [variants, setVariants] = useState([]);
    const [select, setSelect] = useState(false);

    useEffect(() => {
        const fWords = clear(text).includes(" ") ? words.filter(w => clear(w).includes(" ")) : words;

        const getVariant = () => {
            const index = Math.floor(Math.random() * fWords.length);
            const word = flup(fWords[index]);
            if (variants.includes(word)) return getVariant();
            return word;
        }

        const variants = [flup(trans)];

        const count = Math.min(fWords.length, getRandomInt(3, 10));

        for (let i = 1; i < count; i++) {
            variants.push(getVariant());
        }

        setVariants(shuffleArray(variants));
        setSelect(false);
    }, [trans])

    const onClick = (variant) => {
        setSelect(variant);
        setAnswer(variant);
        clear(trans) !== clear(variant) && setIsHinted(true);
    }

    return (
        <div className={css.test}>
            {variants.map(variant => (
                <Button color={select === variant ? clear(trans)===clear(variant)? "success":"error" : "primary"} variant="contained" key={variant} onClick={()=>onClick(variant)}>
                    {variant}
                </Button>
            ))}
        </div>
    )
}
