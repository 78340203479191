import * as React from 'react';
import {useContext} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {CardActionArea} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {MainContext} from "../App";


const convertISO8601ToTime = (isoString) => {
    if (!isoString) return "";
    const hoursMatch = isoString.match(/(\d+)H/);
    const minutesMatch = isoString.match(/(\d+)M/);
    const secondsMatch = isoString.match(/(\d+)S/);
    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    const seconds = secondsMatch ? parseInt(secondsMatch[1], 10) : 0;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

const round = (v) => {
    return (v > 1000000000 && Math.round(v / 1000000000) + "B") || (v > 1000000 && Math.round(v / 1000000) + "M") || (v > 1000 && Math.round(v / 1000) + "K") || v
}

export default function MultiActionAreaCard({item, fontSize}) {
    const {setPrevURL} = useContext(MainContext);

    const navigate = useNavigate();
    const {id, viewCount, likeCount, commentCount, duration, publishedAt, title, url} = item;

    const date = new Date(publishedAt).toString().split(" ").slice(0, 4).join(" ");

    const openVideo = (videoId) => {
        setPrevURL(document.location.pathname);
        navigate(`/video/${videoId}/`);
    }

    return (
        <Card>
            <CardActionArea onClick={() => openVideo(id)}>
                <CardMedia component="img" height={140} image={url} alt={title}/>
                <CardContent>
                    <Typography style={{fontSize}} gutterBottom variant={"body1"} component="div">
                        {title}
                    </Typography>
                    <Typography style={{fontSize}} variant="body2" color="text.secondary">
                        {convertISO8601ToTime(duration)}
                    </Typography>
                    <hr/>
                    {date} &nbsp; &nbsp;👁 {round(viewCount)}&nbsp; 👍 {round(likeCount)}&nbsp; 💬 {round(commentCount)}
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
