import React, {useContext, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Tooltip} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {speak} from "../../common/speak";
import {Server} from "../../common/server";
import {MainContext} from "../../App";
import SearchIcon from "@mui/icons-material/Search";
import {EditVocabulary} from "./edit-vocabulary/edit-vocabulary";
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import {clear} from "../../common/flup";
import {Jumbotron} from "../../common/jumbotron";
import css from "./vocabulary.module.css";

const VocabularyContext = React.createContext({});
const SpeakButton = ({text}) => {
    const {settings: {lang}} = useContext(MainContext);

    return (
        <Button onClick={() => speak(lang, text)}><VolumeUpIcon/></Button>
    )
}
const EditButton = ({index}) => {
    const {items, setItems} = useContext(VocabularyContext);
    const [open, setOpen] = useState(false);

    const [[, text], [, trans]] = items && items[index] ? Object.entries(items[index]) : [["", ""], ["", ""]];

    return (
        <>
            <EditVocabulary
                text={text}
                trans={trans}
                open={open}
                setItems={setItems}
                items={items}
                index={index}
                onClose={() => setOpen(false)}
            />
            <Button onClick={() => setOpen(true)}><EditIcon/></Button>
        </>
    )
}
const AddButton = () => {
    const {items, setItems} = useContext(VocabularyContext);
    const [open, setOpen] = useState(false);

    return (
        <>
            <EditVocabulary
                text={""}
                trans={""}
                open={open}
                setItems={setItems}
                items={items}
                onClose={() => setOpen(false)}
            />
            <Tooltip title="Add to vocabulary" arrow placement="top">
                <Button variant={"contained"} style={{minWidth: 0}} onClick={() => setOpen(true)}>
                    <LibraryAddIcon/>
                    <Box sx={{paddingLeft: 1}}>Add</Box>
                </Button>
            </Tooltip>
        </>
    )
}
const Table = () => {
    const {nItems} = useContext(VocabularyContext);

    return nItems && (
        <table>
            <tbody>
            {nItems.map(([[text, trans, date, notLearned], index]) => (
                <tr key={index} style={{background: notLearned ? "#ffffff" : "#d2ead2"}}>
                    <td><SpeakButton text={text}/></td>
                    <td>{text}</td>
                    <td>{trans}</td>
                    <td><EditButton index={index}/></td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}
const NoWords = () => {
    const {items} = useContext(VocabularyContext);
    return items && !items.length && (
        <Jumbotron>There are no words yet</Jumbotron>
    )
}
const Loading = () => {
    const {items} = useContext(VocabularyContext);
    return !items && (
        <div style={{textAlign: "center"}}><CircularProgress/></div>
    )
}
const SearchInput = () => {
    const {items, setNItems} = useContext(VocabularyContext);
    const [term, setTerm] = useState("");

    useEffect(() => {
        items && setNItems(items.map((item, i) => [Object.values(item), i]).filter(([[text, trans]]) => text.includes(clear(term)) || trans.includes(clear(term))));
    }, [term, items])

    return (
        <div className={css.vocabularySearch}>
            <SearchIcon/>
            <input placeholder="Search…" onChange={e => setTerm(e.target.value)}/>
        </div>
    );
}
export const Vocabulary = () => {
    const [items, setItems] = useState(null);
    const [nItems, setNItems] = useState(null);

    useEffect(() => {
        Server.readFromVocabulary().then(setItems)
    }, []);

    const value = {items, nItems, setNItems, setItems};

    return (
        <VocabularyContext.Provider value={value}>
            <div className={css.vocabulary}>
                <AddButton/>
                <SearchInput/>
                <Loading/>
                <NoWords/>
                <Table/>
            </div>
        </VocabularyContext.Provider>
    )
}

