import React, {useContext} from "react";
import {Button, ButtonGroup, Tooltip} from "@mui/material";
import PlayIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/PauseCircleOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import StartIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import PrevIcon from "@mui/icons-material/KeyboardArrowLeft";
import NextIcon from "@mui/icons-material/KeyboardArrowRight";
import EndIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {VideoContext} from "../../video";
import css from "./buttons.module.css";

const TButton = ({title, Icon, onClick}) => {
    return (
        <Tooltip title={title} arrow placement="bottom">
            <Button variant={"contained"} size={"small"} onClick={onClick}><Icon/></Button>
        </Tooltip>
    )
}

const waitForStatus = (status, player) => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (player.playerInfo.playerState === status) {
                clearInterval(interval);
                resolve();
            }
        })
    })
}

export const Buttons = () => {
    const {isPlaying, player, captions, index} = useContext(VideoContext);

    const goTo = async index => {
        const status = player.playerInfo.playerState;

        if (status === 5) {
            player.mute();
            player.playVideo();
            await waitForStatus(1, player);
            player.pauseVideo();
            await waitForStatus(2, player);
            player.unMute();
        }

        captions && captions[index] && player.seekTo(captions[index][0] / 1000);
        player.pauseVideo();
    }

    const pauseVideo = () => player.pauseVideo();
    const playVideo = () => player.playVideo();
    const replayVideo = () => goTo(index) && player.playVideo();
    const goToPrev = () => goTo(index - 1);
    const goToNext = () => goTo(index + 1);
    const goToStart = () => goTo(0);
    const goToEnd = () => captions && goTo(captions.length - 1);

    return (
        <ButtonGroup fullWidth className={css.buttons}>
            {isPlaying
                ? <TButton title={"Pause"} onClick={pauseVideo} Icon={PauseIcon}/>
                : <TButton title={"Play"} onClick={playVideo} Icon={PlayIcon}/>
            }
            <TButton title={"Replay"} onClick={replayVideo} Icon={ReplayIcon}/>
            <TButton title={"Start"} onClick={goToStart} Icon={StartIcon}/>
            <TButton title={"Prev"} onClick={goToPrev} Icon={PrevIcon}/>
            <TButton title={"Next"} onClick={goToNext} Icon={NextIcon}/>
            <TButton title={"End"} onClick={goToEnd} Icon={EndIcon}/>
        </ButtonGroup>
    )
}
