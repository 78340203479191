import React from 'react';
import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import css from "./paypal-button.module.css";

export function PaypalButton() {
    const initialOptions = {
        clientId: "AbX-7xENMbA-syUKyWvcjXosJRiHwTIBs3WFO-8xdzhVlye8GSSPGjqMHJvsUhlaecDs4-vRrYrP9UBm",
        currency: "USD",
        intent: "capture",
    };

    const createOrder = (data, actions) => actions.order.create({
        purchase_units: [
            {amount: {value: "1.00"}}
        ]
    });

    const onApprove = (data, actions) => actions.order.capture().then((details) => {
        alert("Платеж выполнен успешно! Спасибо, " + details.payer.name.given_name)
    })

    return (
        <div className={css.payButton}>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons createOrder={createOrder} onApprove={onApprove}/>
            </PayPalScriptProvider>
        </div>
    );
}

